<template>
    <div id="i-about-container">
        <div id="i-title-image" />
        <div class="c-div-fullwidth c-background1 c-div-flex-column-center">
            <div class="c-div-flex-column-center">
                <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                    <div class="c-div-fullwidth c-div-flex-column-center">
                        <div class="c-div-fullwidth c-div-flex-column-center">
                            <h3>KOSMETIKA</h3>
                            <h2 class="c-name-text">Markéta Zubrová</h2>
                        </div>
                    </div>
                </div>
                <div class="c-div-flex-column-center c-who-will-care-about-you">
                    <div id="i-me-block" class="c-profile-photo"></div>
                </div>
                <div class="c-about-me">
                    <p class="c-column-text c-background2 c-div-flex-row-center">
                        Ruční nebo přístrojové ošetření, případně ruční kosmetické ošetření v kombinaci s přístrojovou
                        kosmetikou.
                    </p>
                    <p class="c-column-text c-div-flex-row-center">
                        Práce je zároveň mým koníčkem &#x1F60A;
                    </p>
                    <p class="c-column-text c-background2 c-div-flex-row-center">
                        Pracuji pouze s kvalitní a ověřenou kosmetikou, zdokonaluji se a navštěvuji kosmetická školení a
                        kurzy, sleduji novinky v oboru kosmetiky a zahrnuji je do své práce.
                    </p>
                </div>
            </div>
        </div>
        <div class="c-div-fullwidth c-background2 c-div-flex-row-center">
            <div class="c-div-flex-column-center">
                <div
                    class="c-def-mobile-column c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                    <div class="c-div-fullwidth c-div-flex-row-center c-margin-top-on-mobile">
                        <router-link to="/Larens">
                            <div id="i-larens-block" class="c-profile-photo"></div>
                        </router-link>
                    </div>
                    <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                        <div class="c-div-fullwidth c-div-flex-column-center">
                            <div class="c-div-fullwidth c-div-flex-column-center">
                                <h2>Nově jsem rozšířila kosmetický sortiment o produkty firmy LARENS</h2>
                                <p>LARENS představuje moderní kosmetiku pečující o tvář a tělo, která reaguje na potřeby kůže. Vyjímečné produkty pyšnící se patentovou složkou biopeptide complex novinkou známou z farmacie liposomal collagen complex a dalšími více než 70 aktivními látkami. Kosmeceutika Larens vyplňuje mezeru na trhu mezi kosmetickými přípravky a estetickou medicínou.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-company-datail c-background1">
                    <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                        <h2>Pro více detailů o produktech firmy LARENS využijte <a href="https://zubrova.wellu.eu/" style="color:#09901b; font-weight: bold;">TENTO</a> odkaz</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-div-fullwidth c-background1 c-div-flex-row-center">
            <div class="c-div-flex-column-center">
                <div
                    class="c-def-mobile-column-reverse c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                    <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                        <div class="c-div-fullwidth c-div-flex-column-center">
                            <h2>Pracuji také s profesionální španělskou kosmetikou SKEYNDOR.</h2>
                            <p>Kosmetické přípravky této firmy jsou určeny jak pro ošetření v kosmetickém salonu, tak i
                                pro domácí užití.</p>
                        </div>
                    </div>
                    <div class="c-div-fullwidth c-div-flex-row-center c-margin-top-on-mobile">
                        <router-link to="/Skeyndor">
                            <div id="i-skeyndor-block" class="c-profile-photo"></div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
* {
    font-family: Century Gothic, sans-serif;
    font-weight: 100;
}

h1 {
    margin-bottom: 5%;
}

.c-border-left {
    border-left: solid;
    border-color: black;
    border-width: 1px;
}

.c-border-right {
    border-right: solid;
    border-color: black;
    border-width: 1px;
}

.c-about-me {
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    text-align: center;
}

.c-about-me p {
    padding: 4%;
    max-width: 500px;
}

.c-column-text {
    width: 33%;
}

#i-about-title-container {
    width: 100%;
    /* none|h-offset v-offset blur spread color |inset|initial|inherit */
    /* box-shadow: 0px 0px 15px 10px rgb(243, 239, 230); ; */
}

#i-about-container {
    margin-top: 10vh;
    width: 100%;
}

#i-about-container>div {}

#i-about-top-container {}

#i-title-image {
    width: 100%;
    background-image: url('../../public/images/title_photo_edit.jpg');
    background-repeat: no-repeat;
}

#i-me-block {
    background-image: url('@/../public/images/marketa_zubrova.png');
    background-size: cover;
}

#i-skeyndor-block {
    background-image: url('@/../public/images/skeyndor_logo.png');
}

#i-larens-block {
    background-image: url('@/../public/images/larens_2019.png');
}

h1 {
    width: auto;
    font-family: Century Gothic, sans-serif;
    font-weight: bold;
    text-align: center;
}

h2 {
    width: auto;
    font-family: Century Gothic, sans-serif;
    font-weight: 100;
    border-bottom: solid;
    border-width: 1px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.c-profile-photo {
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;

    border-radius: 50%;

    -webkit-filter: grayscale(40%);
    filter: grayscale(40%);
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;

    border: solid;
    border-color: black;
    border-width: 1px;
    box-shadow: 0px 0px 4px 2px rgb(100, 100, 100);
}

.c-profile-photo:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);

    -webkit-transition: .3s ease-in;
    transition: .3s ease-in;
}

.c-map-container {
    width: 100%;
    height: 100%;
}

#i-map-canvas {
    width: 100%;
    height: 100%;
    min-height: 400px;
    background: none !important;
    overflow: hidden;
    margin-top: 20px;

    border: solid;
    border-color: black;
    border-width: 1px;
}

.c-who-will-care-about-you {
    display: flex;
}

.c-company-datail {
    width: 40vw;
    min-width: 350px;
}

@media only screen and (min-width: 720px) {
    .c-company-datail {
        margin-top: 50px;
        max-width: 800px;
    }
    .c-name-text {
        margin-bottom: 40px;
    }

    #i-title-image {
        background-size: cover;
        height: 40vh;
    }

    .c-def-mobile-column {
        flex-direction: row;
    }

    .c-who-will-care-about-you {
        width: 80%;
    }

    .c-margin-top-on-mobile {}

    .c-def-mobile-column-reverse {
        flex-direction: row;
    }

    .c-background1 {
        background-color: rgb(226, 211, 204);
        padding: 4%;
    }

    .c-background2 {
        background-color: rgb(218, 185, 169);
        padding: 4%;
    }
}

@media only screen and (max-width: 719px) {
    .c-company-datail {
        margin-top: 20px;
        max-width: 500px;
    }

    .c-name-text {}

    #i-title-image {
        background-size: cover;
        height: 20vh;
    }

    .c-def-mobile-column {
        flex-direction: column;
    }

    .c-who-will-care-about-you {
        width: 100%;
    }

    .c-margin-top-on-mobile {
        margin-top: 20px;
    }

    .c-def-mobile-column-reverse {
        flex-direction: column-reverse;
    }

    .c-background1 {
        background-color: rgb(226, 211, 204);
        padding: 4%;
        padding-bottom: 7%;
    }

    .c-background2 {
        background-color: rgb(218, 185, 169);
        padding: 4%;
        padding-bottom: 7%;
    }
}
</style>