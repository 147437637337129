<template>
    <div id="i-larens-container">
        <div id="i-service3" class="c-div-fullwidth c-background1 c-div-flex-column-center">
            <div class="c-div-flex-column-center" style="min-width: 300px;">
                <div
                    class="c-div-fullwidth c-def-mobile-column-reverse c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                    <div style="text-align: center; width: 100%;">
                        <div style="text-align: center; width: 100%;">
                            <h2 style="width: 100%; max-width: 380px;">LARENS ošetření</h2>
                        </div>
                    </div>
                </div>
                <table>
                    <tr>
                        <th class="c-price-dropdown-title-text-left">Ošetření</th>
                        <th class="c-price-dropdown-title-text-right">Cena</th>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td class="c-price-dropdown-item-text">Základní ošetření:</td>
                        <td class="c-price-dropdown-item-price">1250 Kč</td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td>
                            <span class="c-price-dropdown-item-text c-text-small" style="font-weight: bold; padding-left: 40px;">Doba trvání: 90 minut</span>
                        </td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td class="c-price-dropdown-item-text">Procedura věčného mládí:</td>
                        <td class="c-price-dropdown-item-price">1600 Kč</td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td>
                            <span class="c-price-dropdown-item-text c-text-small" style="font-weight: bold; padding-left: 40px;">Doba trvání: 90 minut</span>
                        </td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td class="c-price-dropdown-item-text">Liftingové ošetření:</td>
                        <td class="c-price-dropdown-item-price">1450 Kč</td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td>
                            <span class="c-price-dropdown-item-text c-text-small" style="font-weight: bold; padding-left: 40px;">Doba trvání: 90 minut</span>
                        </td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td class="c-price-dropdown-item-text">Ošetření mastné pleti:</td>
                        <td class="c-price-dropdown-item-price">750 Kč</td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td>
                            <span class="c-price-dropdown-item-text c-text-small" style="font-weight: bold; padding-left: 40px;">Doba trvání: 90 minut</span>
                        </td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td class="c-price-dropdown-item-text">TOP ošetření:</td>
                        <td class="c-price-dropdown-item-price">2500 Kč</td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td>
                            <span class="c-price-dropdown-item-text c-text-small" style="font-weight: bold; padding-left: 40px;">Doba trvání: 120 minut</span>
                        </td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td class="c-price-dropdown-item-text" style="font-weight: bold;">Procedura podzim zima</td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td class="c-price-dropdown-item-text">- Chemický peeling Larens:</td>
                        <td class="c-price-dropdown-item-price">1250 Kč</td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td>
                            <span class="c-price-dropdown-item-text c-text-small" style="font-weight: bold; padding-left: 40px;">Doba trvání: 60 minut</span>
                        </td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td class="c-price-dropdown-item-text">- Pro starší a ochablou pleť:</td>
                        <td class="c-price-dropdown-item-price">1600 Kč</td>
                    </tr>
                    <tr class="c-price-dropdown-item">
                        <td>
                            <span class="c-price-dropdown-item-text c-text-small" style="font-weight: bold; padding-left: 40px;">Doba trvání: 90 minut</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div id="i-service2" class="c-div-fullwidth c-background2 c-div-flex-column-center">
            <div class="c-div-flex-column-center">
                <div
                    class="c-def-mobile-column-reverse c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                    <div class="c-div-fullwidth c-div-flex-row-center c-margin-top-on-mobile">
                        <a href="https://zubrova.wellu.eu/">
                            <div class="c-profile-photo"/>
                        </a>
                    </div>
                    <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                        <h2>Pro více detailů o produktech firmy LARENS využijte <a
                                href="https://zubrova.wellu.eu/" style="color:#09901b; font-weight: bold;">TENTO</a> odkaz</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        GetDropDownBaseId(id) {
            const words = id.split('dropdown');

            if (words.length === 1) {
                return id;
            }

            return words[0] + "dropdown";
        },
        OnPriceDropDownControlClick: function (event) {
            if (this.priceDropDownState[event.currentTarget.id] == null) {
                this.priceDropDownState[event.currentTarget.id] = { visible: true };
            } else {
                this.priceDropDownState[event.currentTarget.id].visible
                    = !this.priceDropDownState[event.currentTarget.id].visible;
            }

            const element_base_id = this.GetDropDownBaseId(event.currentTarget.id);

            var element = document.getElementById(element_base_id);

            element.classList.toggle("c-price-dropdown-invisible");
            element.classList.toggle("c-price-dropdown-visible");

            var element_chevron = document.getElementById(element_base_id + '-chevron');

            element_chevron.classList.toggle("c-price-dropdown-control-chevron-open");
            element_chevron.classList.toggle("c-price-dropdown-control-chevron-close");

            var element_control_text = document.getElementById(element_base_id + '-control-text');

            if (element_chevron.classList.contains('c-price-dropdown-control-chevron-open')) {
                element_control_text.innerHTML = 'Více';

            } else {
                element_control_text.innerHTML = 'Skrýt';
            }
        }
    },
    data() {
        return {
            priceDropDownState: {}
        }
    },
    setup() {

    }
}
</script>

<style scoped>
* {
    font-family: Century Gothic, sans-serif;
    font-weight: 100;
}

/* PRICE DROPDOWN */

.c-larens-prod {
    margin-top: 40px;
    background-image: url('../../public/images/larens_prod.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-width: 574px;
    width: auto;
    height: 408px;
    box-shadow: 0px 0px 15px 10px rgb(243, 239, 230);
}

h3 {
    padding: 10px;
    font-weight: bold;
}

.c-price-dropdown-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.c-price-dropdown-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    max-width: 150px;
    height: 70px;
    cursor: pointer;
    /* border-radius: 50%; */
    /* box-shadow: 0px 0px 4px 2px rgb(100, 100, 100); */
    border: solid;
    border-width: 1px;
}

.c-price-dropdown-control-background1 {}

.c-price-dropdown-control-background2 {}

.c-price-dropdown-control:hover {
    color: rgba(0, 0, 0, 0.4);
}

.c-price-dropdown-control-chevron {
    width: 40%;
    height: 40%;
    -webkit-mask-size: contain;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: rgba(0, 0, 0, 1.0);
}

.c-price-dropdown-control-chevron-open {
    -webkit-mask-image: url('../../public/icons/chevron-down.svg');
    mask-image: url('../../public/icons/chevron-down.svg');
}

.c-price-dropdown-control-chevron-close {
    -webkit-mask-image: url('../../public/icons/chevron-up.svg');
    mask-image: url('../../public/icons/chevron-up.svg');
}

.c-price-dropdown-control:hover .c-price-dropdown-control-chevron {
    background-color: rgba(0, 0, 0, 0.4);
}

.c-price-dropdown-control-text {}

.c-price-dropdown-invisible {
    display: none;
    height: 0px;
    transition: height 0.3s ease-out;
}

.c-price-dropdown-visible {
    display: flex;
    height: 500px;
    transition: height 0.3s ease-out;
}

.c-price-dropdown {
    height: 0px;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    width: 100%;
    height: auto;
    transition: height 0.3s ease-out;
    margin-top: 30px;
}

.c-price-dropdown-title-text-left {
    font-weight: bold;
    text-align: left;
    border-bottom: solid;
    border-width: 1px;
    padding-left: 20px;
}

.c-price-dropdown-title-text-right {
    font-weight: bold;
    text-align: right;
    border-bottom: solid;
    border-width: 1px;
    padding-right: 20px;
}

.c-price-dropdown-item {}

.c-price-dropdown-item-text {
    padding-right: 20px;
    text-align: left;
    padding-left: 20px;
    min-width: 250px;
}

.c-price-dropdown-item-price {
    margin-left: 5px;
    text-align: right;
    min-width: 120px;
    padding-right: 20px;
}

/* ************************************************************* */

/* SERVICE 1 */

#i-service1 {}

#i-service1 .c-profile-photo {
    background-image: url('@/../public/images/larens_2019.png');
}

/* ************************************************************* */

/* SERVICE 2 */

#i-service2 {}

#i-service2 .c-profile-photo {
    background-image: url('@/../public/images/larens_prod.png');
    background-size: cover;
}

/* ************************************************************* */

/* SERVICE 3 */

#i-service3 {}

#i-service3 .c-profile-photo {
    background-image: url('@/../public/images/laminaceoboci.png');
    background-position-y: 0px;
}

/* ************************************************************* */

/* SERVICE 4 */

#i-service4 {}

#i-service4 .c-profile-photo {
    background-image: url('@/../public/images/Enzymatickypeeling.jpg');
}

/* ************************************************************* */

/* SERVICE 5 */

#i-service5 {}

#i-service5 .c-profile-photo {
    background-image: url('@/../public/images/ocnimasaz.jpg');
}

/* ************************************************************* */

/* SERVICE 6 */

#i-service6 {}

#i-service6 .c-profile-photo {
    background-image: url('@/../public/images/salon-001mkosmeo.jpg');
}

/* ************************************************************* */

/* SERVICE 7 */

#i-service7 {}

#i-service7 .c-profile-photo {
    background-image: url('@/../public/images/trvala-1.jpg');
    background-size: cover;
    background-position-x: -100px;
    background-position-y: 0px;
}

/* ************************************************************* */

/* SERVICE 8 */

#i-service8 {}

#i-service8 .c-profile-photo {
    background-image: url('@/../public/images/ultrazvukovaspachtle.jpg');
}

/* ************************************************************* */


#i-contacts-title-container {
    width: 100%;
    /* none|h-offset v-offset blur spread color |inset|initial|inherit */
    /* box-shadow: 0px 0px 15px 10px rgb(243, 239, 230); ; */
}

#i-larens-container {
    margin-top: 10vh;
    width: 100%;
}

#i-larens-container>div {}

#i-contacts-top-container {}

h1 {
    width: auto;
    font-family: Century Gothic, sans-serif;
    font-weight: bold;
    text-align: center;
}

h2 {
    width: auto;
    font-family: Century Gothic, sans-serif;
    font-weight: 100;
    border-bottom: solid;
    border-width: 1px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.c-profile-photo {
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;

    border-radius: 50%;

    -webkit-filter: grayscale(40%);
    filter: grayscale(40%);
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;

    border: solid;
    border-color: black;
    border-width: 1px;
    box-shadow: 0px 0px 4px 2px rgb(100, 100, 100);
}

.c-profile-photo:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);

    -webkit-transition: .3s ease-in;
    transition: .3s ease-in;
}

.c-map-container {
    width: 100%;
    height: 100%;
}

.c-background1 {
    background-color: rgb(226, 211, 204);
    padding: 4%;
}

.c-background2 {
    background-color: rgb(218, 185, 169);
    padding: 4%;
}

#i-map-canvas {
    width: 100%;
    height: 100%;
    min-height: 400px;
    background: none !important;
    overflow: hidden;
    margin-top: 20px;

    border: solid;
    border-color: black;
    border-width: 1px;
}

.c-who-will-care-about-you {
    display: flex;
}

@media only screen and (min-width: 720px) {
    table {
        width: 100%;
        max-width: 500px;
    }

    .c-def-mobile-column {
        flex-direction: row;
    }

    .c-def-mobile-column-reverse {
        flex-direction: row;
    }

    .c-who-will-care-about-you {
        width: 80%;
    }

    .c-margin-top-on-mobile {}

    .c-price-dropdown-control {
        margin-top: 40px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 719px) {
    table {
        width: 100%;
        max-width: 500px;
    }
}

@media only screen and (max-width: 600px) {
    table {
        width: 80%;
    }
}

@media only screen and (max-width: 719px) {
    .c-def-mobile-column {
        flex-direction: column;
    }

    .c-def-mobile-column-reverse {
        flex-direction: column-reverse;
    }

    .c-who-will-care-about-you {
        width: 100%;
    }

    .c-margin-top-on-mobile {
        margin-top: 20px;
    }

    .c-price-dropdown-control {
        margin-top: 20px;
    }
}
</style>