<template>
    <div id="i-galery-container c-div-flex-column-center">
        <div class="c-div-fullwidth c-div-flex-column-center">
            <!--<viewer id="i-gallery-viewer" :images="images" :options="options">
                <img v-for="src in images" :key="src" :src="src">
            </viewer>-->
            <vue-picture-swipe id="i-gallery-viewer" :items="[
                {
                    src: 'gallery/0FB5496B-8BAC-4A5E-B5B5-02D2B6934713.jpeg'
                    , thumbnail: 'gallery/0FB5496B-8BAC-4A5E-B5B5-02D2B6934713_thumb.jpeg'
                    , w: 600, h: 400, title: 'Will be used for caption'
                }
                , {
                    src: 'gallery/16BF55B7-91AF-4C74-A521-D5D1EB90578A.jpeg'
                    , thumbnail: 'gallery/16BF55B7-91AF-4C74-A521-D5D1EB90578A_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/1B340903-4EDC-4582-9AFE-71BEDA02021E.jpeg'
                    , thumbnail: 'gallery/1B340903-4EDC-4582-9AFE-71BEDA02021E_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/2CD0ACCF-7F6A-482C-8BF3-7606D1503D2B.jpeg'
                    , thumbnail: 'gallery/2CD0ACCF-7F6A-482C-8BF3-7606D1503D2B_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/216A5688-FA99-47C8-B207-501CF7617709.jpeg'
                    , thumbnail: 'gallery/216A5688-FA99-47C8-B207-501CF7617709_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/55E6E153-11D2-4669-910A-5395E5D3E87A.jpeg'
                    , thumbnail: 'gallery/55E6E153-11D2-4669-910A-5395E5D3E87A_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/66C9CB46-1DF7-41F0-B51E-02641B9A29F4.jpeg'
                    , thumbnail: 'gallery/66C9CB46-1DF7-41F0-B51E-02641B9A29F4_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/6AAABF77-779F-44E3-9D46-9DF0F84B2ECF.jpeg'
                    , thumbnail: 'gallery/6AAABF77-779F-44E3-9D46-9DF0F84B2ECF_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/6E8B18FD-F21E-4CD5-B94E-29017C9D60AC.jpeg'
                    , thumbnail: 'gallery/6E8B18FD-F21E-4CD5-B94E-29017C9D60AC_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/7BCE76F9-3DD5-4EBE-A80F-D2506E04796E.jpeg'
                    , thumbnail: 'gallery/7BCE76F9-3DD5-4EBE-A80F-D2506E04796E_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/865F0280-70D4-4D1D-A98A-E56C383451B6.jpeg'
                    , thumbnail: 'gallery/865F0280-70D4-4D1D-A98A-E56C383451B6_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/A0830E47-DAE1-4630-B747-FAB54B5E4C3A.jpeg'
                    , thumbnail: 'gallery/A0830E47-DAE1-4630-B747-FAB54B5E4C3A_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/A4AE345E-775C-43C8-8C83-4239B88A5A51.jpeg'
                    , thumbnail: 'gallery/A4AE345E-775C-43C8-8C83-4239B88A5A51_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/C6E9E2F9-228C-4C47-BAA9-E959F2AA1CFA.jpeg'
                    , thumbnail: 'gallery/C6E9E2F9-228C-4C47-BAA9-E959F2AA1CFA_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/D0D4804B-33A8-44AF-899B-ABAF68736DF7.jpeg'
                    , thumbnail: 'gallery/D0D4804B-33A8-44AF-899B-ABAF68736DF7_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/E216CAB6-BDA6-4DA0-87F8-F53FD8509E5F.jpeg'
                    , thumbnail: 'gallery/E216CAB6-BDA6-4DA0-87F8-F53FD8509E5F_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/E698AAAB-E7BA-4136-A3D4-7CF9F3AABC6E.jpeg'
                    , thumbnail: 'gallery/E698AAAB-E7BA-4136-A3D4-7CF9F3AABC6E_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/FCF608AF-5AEC-4A6C-8871-A586AA704D14.jpeg'
                    , thumbnail: 'gallery/FCF608AF-5AEC-4A6C-8871-A586AA704D14_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4461.jpeg'
                    , thumbnail: 'gallery/IMG_4461_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4463.jpeg'
                    , thumbnail: 'gallery/IMG_4463_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4464.jpeg'
                    , thumbnail: 'gallery/IMG_4464_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4465.jpeg'
                    , thumbnail: 'gallery/IMG_4465_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4467.jpeg'
                    , thumbnail: 'gallery/IMG_4467_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4468.jpeg'
                    , thumbnail: 'gallery/IMG_4468_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4473.jpeg'
                    , thumbnail: 'gallery/IMG_4473_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4475.jpeg'
                    , thumbnail: 'gallery/IMG_4475_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4476.jpeg'
                    , thumbnail: 'gallery/IMG_4476_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/2E64299F-4A51-4072-AAEC-4CA964DFC884.jpeg'
                    , thumbnail: 'gallery/2E64299F-4A51-4072-AAEC-4CA964DFC884_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4462.jpeg'
                    , thumbnail: 'gallery/IMG_4462_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4466.jpeg'
                    , thumbnail: 'gallery/IMG_4466_thumb.jpeg'
                    , w: 600, h: 400
                }
                , {
                    src: 'gallery/IMG_4474.jpeg'
                    , thumbnail: 'gallery/IMG_4474_thumb.jpeg'
                    , w: 600, h: 400
                }
            ]"></vue-picture-swipe>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {
        show() {
            this.$viewerApi({
                images: this.images,
            })
        },
    },
    beforeMount() {
        /* this.$gallery.addEventListener('ready', () => {
            window.scrollTo(0, 0);
            this.$gallery.removeEventListener('ready');
        }); */
    },
    mounted() { },
    beforeUnmount() { }
}
</script>

<style scoped>
* {
    font-family: Century Gothic, sans-serif;
    font-weight: 100;
}

img {
    cursor: pointer;
    max-width: 12%;
    padding: 1%;
}

#i-gallery-viewer {
    margin-top: 10vh;
    margin-bottom: 10vh;
    display: flex;
    align-items: baseline;
    align-content: stretch;
    flex-grow: 1;
    flex-basis: 20%;
    flex-direction: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 928px) {
    #i-gallery-viewer {
        max-width: 930px;
    }
}

@media only screen and (min-width: 616px) and (max-width: 927px) {
    #i-gallery-viewer {
        max-width: 620px;
    }
}

@media only screen and (max-width: 615px) {
    #i-gallery-viewer {
        max-width: 310px;
    }
}

#i-galery-container {
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: black;
    /* none|h-offset v-offset blur spread color |inset|initial|inherit */
    /* box-shadow: 0px 0px 15px 10px rgb(243, 239, 230); ; */
    box-shadow: 0px 0px 4px 2px rgb(100, 100, 100);
}

h1 {
    width: auto;
    font-family: Century Gothic, sans-serif;
    font-weight: bold;
    text-align: center;
}

h2 {
    font-family: Century Gothic, sans-serif;
    font-weight: 100;
    border-bottom: solid;
    border-width: 1px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

table {}

thead {
    width: 100%;
    margin-bottom: 10px;
}

tbody {
    width: 100%;
}

tfoot {
    width: 100%;
}

.c-profile-photo {
    min-width: 300px;
    min-height: 300px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 400px;
    margin-top: 5%;
    border-radius: 50%;

    border: solid;
    border-color: black;
    border-width: 1px;

    -webkit-filter: grayscale(40%);
    filter: grayscale(40%);
    -webkit-transition: .3s ease-in;
    transition: .3s ease-in;
}

.c-profile-photo:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);

    background-size: 500px;
}

.c-map-container {
    width: 100%;
    height: 100%;
}

#i-map-canvas {
    width: 100%;
    height: 100%;
    min-height: 400px;
    background: none !important;
    overflow: hidden;
    margin-top: 20px;

    border: solid;
    border-color: black;
    border-width: 1px;
}



</style>