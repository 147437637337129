<template>
    <footer class="c-div-flex-row">
        <!-- KONTAKT mkosmeo@gmail.com<br />+420 774 277 333 -->
        <div class="c_footer_author c-div-flex-row-center">
            Vytvořil Petr H. &#169; 2022
        </div>
        <div class="c_footer_contacts">
            <a class="footer_item_pic c-div-flex-row-center footer-item-pic-margin-right" href="https://instagram.com/mkosmeo?igshid=YmMyMTA2M2Y%3D">
                <div id="footer_item_instagram" class="c_footer_logo_size c_backface_visibility_hidden" />
            </a>
            <a class="footer_item_pic c-div-flex-row-center" href="https://www.facebook.com/profile.php?id=100068038933626">
                <div id="footer_item_facebook" class="c_footer_logo_size c_backface_visibility_hidden" />
            </a>
        </div>
    </footer>
</template>

<script>
export default {
    mounted() { },
    components: {},
    watch: {},
    methods: {},
    computed: {}
}
</script>

<style>
@import "../assets/styles.css";

footer {
    font-family: Century Gothic, sans-serif;
    background-color: rgb(14, 14, 14);
    color: white;
    width: 100%;
    height: 150px;
    padding: 1%;
}

.c_footer_author {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    font-size: 15px;
}

.c_footer_contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    max-width: 150px;
    height: 100%;
}

.c_footer_logo_size {
    width: 48px;
    height: 48px;
}

.footer_item_pic {
    width: 100%;
    height: 100%;
}

.footer-item-pic-margin-right {
    margin-right: 0%;
}

#footer_item_instagram {
    width: 75%;
    max-width: 60px;
    height: 75%;
    max-height: 60px;

    -webkit-transition: 200ms background-color linear;
    -moz-transition: 200ms background-color linear;
    -moz-transition: 200ms background-color linear;
    -ms-transition: 200ms background-color-ms-filter linear;
    -o-transition: 200ms background-color linear;
    transition: 200ms background-color linear;

    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-image: url('../assets/icons/instagram.svg');
    mask-image: url('../assets/icons/instagram.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: white;
}

#footer_item_instagram:hover {
    background-color: rgb(238, 66, 172);
}

#footer_item_facebook {
    width: 75%;
    max-width: 60px;
    height: 75%;
    max-height: 60px;

    -webkit-transition: 200ms background-color linear;
    -moz-transition: 200ms background-color linear;
    -moz-transition: 200ms background-color linear;
    -ms-transition: 200ms background-color-ms-filter linear;
    -o-transition: 200ms background-color linear;
    transition: 200ms background-color linear;

    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-image: url('../assets/icons/facebook.svg');
    mask-image: url('../assets/icons/facebook.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: white;
}

#footer_item_facebook:hover {
    background-color: rgb(64, 116, 212);
}

@media only screen and (min-width: 720px) {
    .c_footer_contacts {
        flex-direction: row;
    }
}

@media only screen and (max-width: 719px) {
    .c_footer_contacts {
        flex-direction: column;
    }

    .footer_item_pic {
        max-width: 60px;
        max-height: 60px;
    }
}
</style>
