<template>
  <div id="i-main-container">
    <AppHeader />
    <div id="i-content-container" class="c-div-flex-column-center">
      <div id="i-content-container-inner">
        <router-view></router-view>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppHeader
    , AppFooter
  }
}
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;         /* Opera/IE 8+ */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */

  font-family: Century Gothic, sans-serif;
  font-weight: 100;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgb(218, 185, 169);
  /* background: radial-gradient(ellipse at top, rgb(92, 255, 63) 10%, rgb(0, 110, 6) 89%); */
  width: 100%;
  min-height: 100vh;
  /* overflow-x: hidden; */
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#i-main-container {
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#i-content-container {
  width: 100%;
  height: 100%;
  flex: 1;
}

#i-content-container-inner {
  width: 100vw;
  height: 100%;
}
</style>
