import { createApp } from 'vue';

import App from './App.vue';

import router from './router';

import '../../../assets/common_styles.css'

// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'
import VuePictureSwipe from 'vue3-picture-swipe';

const app = createApp(App);

app.directive('resize', {
    inserted: function(el, binding) {
      const onResizeCallback = binding.value;
      window.addEventListener('resize', () => {
        const width = document.documentElement.clientWidth;
        const height = document.documentElement.clientHeight;
        onResizeCallback({ width, height });
      })
    }
  });

const productionTip = false;

app.config.globalProperties.$hostname_front = (productionTip) ? 'https://mkosmeo.cz' : 'http://localhost:3044';
app.config.globalProperties.$hostname_back = (productionTip) ? 'https://mkosmeo.cz' : 'http://localhost:8080';
// app.config.globalProperties.$gallery = VueViewer;
// app.config.globalProperties.$gallery = PhotoSwipe;

// TODO: resize DEBOUNCING

// app.use(VueViewer);
// app.use(PhotoSwipe);
app.component('vue-picture-swipe', VuePictureSwipe);
app.use(router);
app.mount('#app');
