<template>
    <!--<AppInfoArea />-->
    <header>
        <div>
            <div class="c-div-fullwidth c-div-flex-row c-div-flex-align-center">
                <AppMenu />
            </div>
            <div id="i-nav-logo-container" class="c-div-fullwidth c-div-flex-row-center">
                <router-link class="nav-logo" to="/"></router-link>
            </div>
        </div>
    </header>
</template>

<script>
import AppMenu from "./AppMenu.vue"

export default {
    components: {
        AppMenu
    }
}
</script>

<style scoped>
header>div {
    display: flex;
    min-height: 60px;
    margin: auto;
}

header>div {
    width: 90%;
    border-bottom: solid;
    border-color: black;
    border-width: 2px;
    justify-content: left;
}

.nav-logo {
    background-image: url('../assets/logo_test.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

@media only screen and (min-width: 720px) {
    header>div {
        flex-direction: row;
        height: 10vh;
    }

    #i-nav-logo-container {
        width: 30%;
    }

    .nav-logo {
        height: 70%;
        background-position: right;
    }

    .nav-col-hidden {}

    .nav-row-hidden {
        display: none;
    }
}

@media only screen and (max-width: 719px) {
    header>div {
        flex-direction: column-reverse;
        justify-content: center;
    }

    nav {
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        justify-content: center;
    }

    #i-nav-logo-container {
        width: 100%;
        height: 120px;
        border-bottom: solid;
        border-color: black;
        border-width: 2px;
    }

    .nav-logo {
        height: 70px;
        background-position: center;
    }

    .nav-col-hidden {
        display: none;
    }

    .nav-row-hidden {}
}
</style>