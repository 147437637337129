<template>
    <div id="i-contacts-container">
        <div id="i-contacts-top-container" class="c-div-fullwidth c-background1 c-div-flex-row-center">
            <div class="c-def-column c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                <div class="c-div-fullwidth c-div-flex-column-center">
                    <h2>Bude o Vás pečovat:</h2>
                    <h3>Markéta Zubrová - Kosmetička</h3>
                </div>
                <div class="c-div-fullwidth c-div-flex-row-center c-margin-top-on-mobile">
                    <div class="c-profile-photo"></div>
                </div>
            </div>
        </div>

        <div class="c-div-fullwidth c-background2 c-div-flex-column-center">
            <h2>Kdy mě můžete navštívit:</h2>
            <span>Úterý: 09:00 - 19:30</span>
            <span>Středa: 09:00 - 19:30</span>
            <span>Čtvrtek: 09:00 - 19:30</span>
        </div>

        <div class="c-div-fullwidth c-background1 c-div-flex-column-center">
            <h2>Rezervace termínu:</h2>
            <span>Mobil: +420 774 277 333</span>
            <small class="c-text-align-center">Možnost rezervace i při osobním setkání.</small>
        </div>

        <div class="c-div-fullwidth c-where-to-find-us c-def-column c-div-flex-justify-center c-div-flex-align-center c-margin-top-on-mobile c-text-align-center" style="padding-bottom: 40px;">
            <div class="c-where-to-find-us c-div-flex-justify-center c-div-flex-align-center">
                <div class="c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                    <div class="c-div-fullwidth c-background2 c-div-flex-column-center" style="min-width: 400px;">
                        <h2>Kde mě najdete:</h2>
                        <span>Studio SONATA</span>
                        <span>Praha 3, 130 00 - Vinohrady</span>
                        <span>Studio SONATA<br /></span>
                    </div>
                </div>
            </div>
            <div class="c-margin-left-on-desktop" style="min-width: 400px; max-width: 500px;">
                <iframe id="i-map-canvas"
                    src="https://maps.google.com/maps?q=Studio%20SONATA&t=&z=17&ie=UTF8&iwloc=&output=embed" />
            </div>
        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
* {
    font-family: Century Gothic, sans-serif;
    font-weight: 100;
}

#i-contacts-title-container {
    width: 100%;
    /* none|h-offset v-offset blur spread color |inset|initial|inherit */
    /* box-shadow: 0px 0px 15px 10px rgb(243, 239, 230); ; */
}

#i-contacts-container {
    margin-top: 10vh;
    width: 100%;
}

#i-contacts-container>div {}

#i-contacts-top-container {}

h1 {
    width: auto;
    font-family: Century Gothic, sans-serif;
    font-weight: bold;
    text-align: center;
}

h2 {
    width: auto;
    font-family: Century Gothic, sans-serif;
    font-weight: 100;
    border-bottom: solid;
    border-width: 1px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.c-profile-photo {
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    border-radius: 50%;

    background-image: url('@/../public/images/marketa_zubrova.png');

    -webkit-filter: grayscale(40%);
    filter: grayscale(40%);
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;

    border: solid;
    border-color: black;
    border-width: 1px;
    box-shadow: 0px 0px 4px 2px rgb(100, 100, 100);
}

.c-profile-photo:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);

    -webkit-transition: .3s ease-in;
    transition: .3s ease-in;
}

.c-map-container {
    width: 100%;
    height: 100%;
}

.c-background1 {
    background-color: rgb(226, 211, 204);
    padding: 4%;
}

.c-background2 {
    background-color: rgb(218, 185, 169);
    padding: 4%;
}

#i-map-canvas {
    width: 100%;
    height: 100%;
    min-height: 400px;
    background: none !important;
    overflow: hidden;
    margin-top: 30px;

    border: solid;
    border-color: black;
    border-width: 1px;
}

.c-where-to-find-us {
    display: flex;
}

.c-who-will-care-about-you {
    display: flex;
}

@media only screen and (min-width: 720px) {
    .c-def-column {
        flex-direction: row;
    }

    .c-who-will-care-about-you {
        width: 80%;
    }

    .c-margin-top-on-mobile {}

    .c-margin-left-on-desktop {
        margin-left: 40px;
    }
}

@media only screen and (max-width: 719px) {
    .c-def-column {
        flex-direction: column;
    }

    .c-who-will-care-about-you {
        width: 100%;
    }

    .c-margin-top-on-mobile {
        margin-top: 20px;
    }

    .c-margin-left-on-desktop {}
}
</style>