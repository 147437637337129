import { createRouter, createWebHashHistory } from 'vue-router';

import ViewHome from "./components/AppAbout";
import ViewGalery from "./components/AppGalery";
import ViewContacts from "./components/AppContacts";
import ViewServices from "./components/AppServices";
import ViewSkeyndor from "./components/AppSkeyndor";
import ViewLarens from "./components/AppLarens";
import ViewInstrumentalTreatments from "./components/AppInstrumentalTreatments";

const routes = [
    { path: '/', component: ViewHome },
    { path: '/Galery', component: ViewGalery },
    { path: '/Contacts', component: ViewContacts },
    { path: '/Services', component: ViewServices },
    { path: '/Skeyndor', component: ViewSkeyndor },
    { path: '/Larens', component: ViewLarens },
    { path: '/InstrumentalTreatments', component: ViewInstrumentalTreatments }
];

const router = createRouter(
    {
        history: createWebHashHistory()
        , routes
    }
);

export default router;