<template>
    <nav id="i-nav" v-resize="setViewportDimensions">
        <div class="nav-item c-div-flex-row-center nav-button-margin-bottom">
            <router-link class="nav-button noselect" to="/">
                Úvod
            </router-link>
        </div>
        <div class="nav-item c-div-flex-row-center nav-button-margin-left nav-button-margin-bottom">
            <router-link class="nav-item nav-button noselect c-div-flex-row-center" to="/Services">
                Služby
            </router-link>
        </div>
        <div class="nav-item c-div-flex-row-center nav-button-margin-left nav-button-margin-bottom">
            <router-link class="nav-item nav-button noselect c-div-flex-row-center" to="/Larens">
                Larens
            </router-link>
        </div>
        <div class="nav-item c-div-flex-row-center nav-button-margin-left nav-button-margin-bottom">
            <router-link class="nav-item nav-button noselect c-div-flex-row-center" to="/Skeyndor">
                Skeyndor
            </router-link>
        </div>
        <div class="nav-item c-div-flex-row-center nav-button-margin-left nav-button-margin-bottom">
            <router-link class="nav-item nav-button noselect c-div-flex-row-center" to="/InstrumentalTreatments">
                Přístrojová ošetření
            </router-link>
        </div>
        <div class="nav-item c-div-flex-row-center nav-button-margin-left nav-button-margin-bottom">
            <router-link class="nav-item nav-button noselect c-div-flex-row-center" to="/Galery">
                Galerie
            </router-link>
        </div>
        <div class="nav-item c-div-flex-row-center nav-button-margin-left nav-button-margin-bottom">
            <router-link class="nav-item nav-button noselect c-div-flex-row-center" to="/Contacts">
                Kontakt
            </router-link>
        </div>
    </nav>
</template>

<script>
export default {
    props: {
        msg: String
    },
    data() {
        return {
            title: "Kontakty",
            minViewportWidth: 720,
            viewportWidth: document.documentElement.clientWidth,
            viewportHeight: document.documentElement.clientHeight,
            verticalMenuShown: false,
            menuStyleChangeToHorizontalActionPerformed: false
        }
    },
    setup() {

    },
    methods: {
        setViewportDimensions(event) {
            event;

            this.viewportWidth = document.documentElement.clientWidth;
            this.viewportHeight = document.documentElement.clientHeight;

            if (this.viewportWidth >= this.minViewportWidth) {
                if (!this.menuStyleChangeToHorizontalActionPerformed) {
                    this.verticalMenuShown = false;
                }
            } else {
                this.menuStyleChangeToHorizontalActionPerformed = false;
                this.verticalMenuShown = true;
            }
        }
    },
    mounted() {
        window.addEventListener("resize", this.setViewportDimensions);
    },
    unmounted() {
        window.removeEventListener("resize", this.setViewportDimensions);
    }
}
</script>

<style scoped>
@import "../assets/styles.css";

a {
    text-decoration: none;
}

#i-nav {
    /* background-color: rgb(97, 199, 136); */
    width: 100%;
    height: 100%;
}

.c-nav-chevron {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999999999;

    -webkit-mask-size: contain;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: rgba(0, 0, 0, 1.0);
    transition: background-color 0.2s ease-out;
}

.nav-item {
    cursor: pointer;
}

.c-nav-chevron-open {
    -webkit-mask-image: url('../../public/icons/chevron-down.svg');
    mask-image: url('../../public/icons/chevron-down.svg');

    background-color: rgba(0, 0, 0, 0.0);
    transition: background-color 0.3s ease-out;
}

.c-nav-chevron-close {
    -webkit-mask-image: url('../../public/icons/x.svg');
    mask-image: url('../../public/icons/x.svg');

    background-color: rgba(0, 0, 0, 0.0);
    transition: background-color 0.3s ease-out;

    display: none;
}

.c-nav-chevron-close-static {
    background-color: rgba(0, 0, 0, 1.0);
    transition: none;
}

.c-nav-chevron-close-transition {}

.nav-button {
    font-family: Century Gothic, sans-serif;
    font-size: 20px;
    color: black;
    text-decoration: none;
    box-sizing: content-box;
}

@media only screen and (min-width: 720px) {
    #i-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .nav-item {
        width: auto;
    }

    .nav-button {
        width: auto;
        height: 25px;
        display: inline-block;
        position: relative;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .nav-button:hover {
        cursor: pointer;
    }

    .nav-button:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    .nav-button:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: black;
        transform-origin: bottom right;
        transition: transform 0.15s ease-out;
    }

    .nav-button-margin-left {
        margin-left: 3%;
    }

    .nav-button-margin-bottom {
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 719px) {
    #i-nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nav-item {
        width: 100%;
    }

    .nav-button {
        width: auto;
        height: 25px;
        display: inline-block;
        position: relative;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .nav-button:hover {
        cursor: pointer;
    }

    .nav-button:hover:after {
        transform: scaleX(1);
        transform-origin: bottom center;
    }

    .nav-button:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: black;
        transform-origin: bottom center;
        transition: transform 0.15s ease-out;
    }

    .nav-button-margin-left {
        margin-left: 0px;
    }

    .nav-button-margin-bottom {
        margin-bottom: 10px;
    }
}
</style>