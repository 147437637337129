<template>
    <div id="i-contacts-container">
        <div id="i-service1" class="c-div-fullwidth c-background1 c-div-flex-column-center">
            <div class="c-div-flex-column-center">
                <div
                    class="c-def-mobile-column-reverse c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                    <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                        <h2>Depilace:</h2>
                        <span class="c-text-align-center">Teplý vosk pomocí depilátoru, který má stálou teplotu se
                            nanese na pokožku, přiloží se
                            depilační papírky a rychlým pohybem i s chloupky strhneme. Po depilace se kůže ošetří
                            protizánětlivým přípravkem. Pravidelnou depilací dochází k zeslábnutí a úbytku
                            chloupků.</span>
                    </div>
                    <div class="c-div-fullwidth c-div-flex-row-center c-margin-top-on-mobile">
                        <div class="c-profile-photo"></div>
                    </div>
                </div>
            </div>
            <div class="c-price-dropdown-area">
                <div id="i-service1-dropdown-control"
                    class="noselect c-price-dropdown-control c-price-dropdown-control-background1"
                    v-on:click="OnPriceDropDownControlClick($event)">
                    <span id="i-service1-dropdown-control-text" class="c-price-dropdown-control-text">Ceník</span>
                    <div id="i-service1-dropdown-chevron"
                        class="c-price-dropdown-control-chevron c-price-dropdown-control-chevron-open" />
                </div>
                <div id="i-service1-dropdown" class="noselect c-price-dropdown c-price-dropdown-invisible">
                    <table>
                        <tr>
                            <th class="c-price-dropdown-title-text-left">Partie</th>
                            <th class="c-price-dropdown-title-text-right">Cena</th>
                        </tr>
                        <tr class="c-price-dropdown-item">
                            <td class="c-price-dropdown-item-text">Brada:</td>
                            <td class="c-price-dropdown-item-price">90 Kč</td>
                        </tr>
                        <tr class="c-price-dropdown-item">
                            <td class="c-price-dropdown-item-text">Horní ret:</td>
                            <td class="c-price-dropdown-item-price">90 Kč</td>
                        </tr>
                        <tr class="c-price-dropdown-item">
                            <td class="c-price-dropdown-item-text">Brada:</td>
                            <td class="c-price-dropdown-item-price">90 Kč</td>
                        </tr>
                        <tr class="c-price-dropdown-item">
                            <td class="c-price-dropdown-item-text">Tváře:</td>
                            <td class="c-price-dropdown-item-price">130 Kč</td>
                        </tr>
                        <tr class="c-price-dropdown-item">
                            <td class="c-price-dropdown-item-text">Nohy (od kolen dolů):</td>
                            <td class="c-price-dropdown-item-price">300 Kč</td>
                        </tr>
                        <tr class="c-price-dropdown-item">
                            <td class="c-price-dropdown-item-text">Nohy (celé):</td>
                            <td class="c-price-dropdown-item-price">600 Kč</td>
                        </tr>
                        <tr class="c-price-dropdown-item">
                            <td class="c-price-dropdown-item-text">Ruce (k lokti):</td>
                            <td class="c-price-dropdown-item-price">200 Kč</td>
                        </tr>
                        <tr class="c-price-dropdown-item">
                            <td class="c-price-dropdown-item-text">Ruce (celé):</td>
                            <td class="c-price-dropdown-item-price">350 Kč</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div id="i-service2" class="c-div-fullwidth c-background2 c-div-flex-column-center">
            <div
                class="c-def-mobile-column c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                <div class="c-div-fullwidth c-div-flex-row-center c-margin-top-on-mobile">
                    <div class="c-profile-photo"></div>
                </div>
                <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                    <h2>Enzymatický peeling:</h2>
                    <span class="c-text-align-center">Enzymatický peeling je jemný pudrový peeling, který odstraní z
                        pleti pomocí enzymů
                        nadbytečný maz, odumřelé buňky a kožní nečistoty, rozjasňuje a osvěžuje pokožku, taktéž
                        povzbuzuje buňkovou obnovu. Je vhodný pro intenzivní a přitom šetrné hloubkové čištění pleti
                        doplněné kosmetickým ošetřením v salónu. Enzymatický peeling je ideální pro pleť s kuperózou, s
                        citlivou pletí, se sklonem k praskaní cévek, suchou nebo promaštěnou pokožkou. V důsledku toho
                        budou vrásky vyhlazené a proces stárnutí se zpomaluje. Výtažky z aloe, alantoin a vitamíny E, C
                        a
                        F zvlhčují, utišují podráždění a likvidují volné radikály, zanechávají pleť hedvábně hladkou a
                        zrelaxovanou.</span>
                </div>
            </div>
            <div class="c-price-dropdown-area">
                <div id="i-service2-dropdown-control"
                    class="noselect c-price-dropdown-control c-price-dropdown-control-background1"
                    v-on:click="OnPriceDropDownControlClick($event)">
                    <span id="i-service2-dropdown-control-text" class="c-price-dropdown-control-text">Ceník</span>
                    <div id="i-service2-dropdown-chevron"
                        class="c-price-dropdown-control-chevron c-price-dropdown-control-chevron-open" />
                </div>
                <div id="i-service2-dropdown" class="noselect c-price-dropdown c-price-dropdown-invisible">
                    <table>
                        <tr>
                            <th class="c-price-dropdown-title-text-left">Více informací u Vaší kosmetičky.</th>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div id="i-service3" class="c-div-fullwidth c-background1 c-div-flex-column-center">
            <div
                class="c-def-mobile-column-reverse c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                    <h2>Laminace obočí:</h2>
                    <span class="c-text-align-center">Jedná o úpravu a tvarování přírodního obočí, které lze vytvarovat
                        a zafixovat do požadovaného tvaru. Obočí se zvýrazní, opticky se zahustí a získá větší
                        objem.</span>
                </div>
                <div class="c-div-fullwidth c-div-flex-row-center c-margin-top-on-mobile">
                    <div class="c-profile-photo"></div>
                </div>
            </div>
            <div class="c-price-dropdown-area">
                <div id="i-service3-dropdown-control"
                    class="noselect c-price-dropdown-control c-price-dropdown-control-background1"
                    v-on:click="OnPriceDropDownControlClick($event)">
                    <span id="i-service3-dropdown-control-text" class="c-price-dropdown-control-text">Ceník</span>
                    <div id="i-service3-dropdown-chevron"
                        class="c-price-dropdown-control-chevron c-price-dropdown-control-chevron-open" />
                </div>
                <div id="i-service3-dropdown" class="noselect c-price-dropdown c-price-dropdown-invisible">
                    <table>
                        <tr>
                            <th class="c-price-dropdown-title-text-left">500 Kč</th>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div id="i-service4" class="c-div-fullwidth c-background2 c-div-flex-column-center">
            <div
                class="c-def-mobile-column c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                <!--<div class="c-div-fullwidth c-div-flex-row-center c-margin-top-on-mobile">
                    <div class="c-profile-photo"></div>
                </div>-->
                <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                    <h2>Lash lifting + botox + barvení:</h2>
                    <span class="c-text-align-center">Nejedná se o trvalou na řasy. Tato technika Lash Lifting a
                        následně Lash Botox spočívá v napnutí a zatočení řas a na speciální silikonovou formu pomocí
                        tekutin. Výsledným efektem jsou zatočené a jakoby vytažené řasy směrem k obočí. Následně se
                        barví a poslední krok je Lash Botox, díky kterému dokáže řasa pojmout velké množství výživy.
                        Vhodné pro klienty, kteří mají rovné řasy směřující dolů, pro ty, kteří nechtějí prodloužené
                        řasy a pro klienty, kteří řasy nosili a po sundání jsou jejich řasy rovné.</span>
                </div>
            </div>
            <div class="c-price-dropdown-area">
                <div id="i-service4-dropdown-control"
                    class="noselect c-price-dropdown-control c-price-dropdown-control-background1"
                    v-on:click="OnPriceDropDownControlClick($event)">
                    <span id="i-service4-dropdown-control-text" class="c-price-dropdown-control-text">Ceník</span>
                    <div id="i-service4-dropdown-chevron"
                        class="c-price-dropdown-control-chevron c-price-dropdown-control-chevron-open" />
                </div>
                <div id="i-service4-dropdown" class="noselect c-price-dropdown c-price-dropdown-invisible">
                    <table>
                        <tr>
                            <th class="c-price-dropdown-title-text-left">1100 Kč</th>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div id="i-service5" class="c-div-fullwidth c-background1 c-div-flex-column-center">
            <div
                class="c-def-mobile-column-reverse c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                    <h2>Mikromasáž očního okolí:</h2>
                    <span class="c-text-align-center">
                        <p>
                            Masáž významně potlačuje prohloubování vrásek, zmenšuje váčky pod očima, snižuje se
                            nitrooční
                            tlak čímž dochází k posilování zraku a zároveň lepšímu zprůchodnění dutin. Mikromasáž
                            napomáhá
                            vyplavit toxiny, které odvádí do lymfatických cest, podporuje obnovu buněk a zabraňuje
                            ztrátě
                            vlhkosti pokožky. Po provedení mikromasáže očního okolí získají Vaše oči jiskřivý a
                            mladistvý
                            vzhled. Mikromasáž očního okolí trvá přibližně 15 minut a lze ji provádět jako samostatný
                            úkon
                            nebo v rámci celkového kosmetického ošetření.
                        </p>
                    </span>
                </div>
                <div class="c-div-fullwidth c-div-flex-row-center c-margin-top-on-mobile">
                    <div class="c-profile-photo"></div>
                </div>
            </div>
            <div class="c-price-dropdown-area">
                <div id="i-service5-dropdown-control"
                    class="noselect c-price-dropdown-control c-price-dropdown-control-background1"
                    v-on:click="OnPriceDropDownControlClick($event)">
                    <span id="i-service5-dropdown-control-text" class="c-price-dropdown-control-text">Ceník</span>
                    <div id="i-service5-dropdown-chevron"
                        class="c-price-dropdown-control-chevron c-price-dropdown-control-chevron-open" />
                </div>
                <div id="i-service5-dropdown" class="noselect c-price-dropdown c-price-dropdown-invisible">
                    <table>
                        <tr>
                            <th class="c-price-dropdown-title-text-left">200 Kč</th>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div id="i-service7" class="c-div-fullwidth c-background2 c-div-flex-column-center">
            <div
                class="c-def-mobile-column-reverse c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                    <h2>Trvalá na řasy + barvení:</h2>
                    <span class="c-text-align-center">
                        Trvalá na řasy je jednoduchá a dlouhotrvající pomoc pro vaše oči. Zvýrazní přirozenou křivku řas
                        na dobu 2-3 měsíců. Tohoto okamžitého efektu se docílí použitím malých natáček, speciálního
                        lepidla, kterým se natáčky připevní, působením gelu (trvalé) a neutralizačního roztoku. Úplně na
                        závěr se řasy ještě obarví hnědou nebo černou barvou. Trvalá na řasy je vhodná jak pro dlouhé,
                        tak i pro krátké řasy. Nijak vašim řasám neškodí. O řasy se staráme a pokud se líčíte,
                        používejte na odlíčení olejíček pro regeneraci řas. Nevadí kontaktním čočkám.
                    </span>
                </div>
                <div class="c-div-fullwidth c-div-flex-row-center c-margin-top-on-mobile">
                    <div class="c-profile-photo"></div>
                </div>
            </div>
            <div class="c-price-dropdown-area">
                <div id="i-service7-dropdown-control"
                    class="noselect c-price-dropdown-control c-price-dropdown-control-background1"
                    v-on:click="OnPriceDropDownControlClick($event)">
                    <span id="i-service7-dropdown-control-text" class="c-price-dropdown-control-text">Ceník</span>
                    <div id="i-service7-dropdown-chevron"
                        class="c-price-dropdown-control-chevron c-price-dropdown-control-chevron-open" />
                </div>
                <div id="i-service7-dropdown" class="noselect c-price-dropdown c-price-dropdown-invisible">
                    <table>
                        <tr>
                            <th class="c-price-dropdown-title-text-left">550 Kč</th>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div id="i-service9" class="c-div-fullwidth c-background1 c-div-flex-column-center">
            <div
                class="c-def-mobile-column c-div-flex-align-center c-div-flex-justify-center c-who-will-care-about-you">
                <div class="c-div-fullwidth c-div-flex-column-center c-margin-top-on-mobile c-text-align-center">
                    <h2>Další služby:</h2>
                </div>
            </div>
            <table>
                        <tr>
                            <th class="c-price-dropdown-title-text-left">Služba</th>
                            <th class="c-price-dropdown-title-text-right">Cena</th>
                        </tr>
                        <tr class="c-price-dropdown-item">
                            <td class="c-price-dropdown-item-text">Úprava obočí:</td>
                            <td class="c-price-dropdown-item-price">140 Kč</td>
                        </tr>
                        <tr class="c-price-dropdown-item">
                            <td class="c-price-dropdown-item-text">Barvení obočí:</td>
                            <td class="c-price-dropdown-item-price">120 Kč</td>
                        </tr>
                    </table>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        GetDropDownBaseId(id) {
            const words = id.split('dropdown');

            if (words.length === 1) {
                return id;
            }

            return words[0] + "dropdown";
        },
        OnPriceDropDownControlClick: function (event) {
            if (this.priceDropDownState[event.currentTarget.id] == null) {
                this.priceDropDownState[event.currentTarget.id] = { visible: true };
            } else {
                this.priceDropDownState[event.currentTarget.id].visible
                    = !this.priceDropDownState[event.currentTarget.id].visible;
            }

            const element_base_id = this.GetDropDownBaseId(event.currentTarget.id);

            var element = document.getElementById(element_base_id);

            element.classList.toggle("c-price-dropdown-invisible");
            element.classList.toggle("c-price-dropdown-visible");

            var element_chevron = document.getElementById(element_base_id + '-chevron');

            element_chevron.classList.toggle("c-price-dropdown-control-chevron-open");
            element_chevron.classList.toggle("c-price-dropdown-control-chevron-close");

            var element_control_text = document.getElementById(element_base_id + '-control-text');

            if (element_chevron.classList.contains('c-price-dropdown-control-chevron-open')) {
                element_control_text.innerHTML = 'Ceník';

            } else {
                element_control_text.innerHTML = 'Skrýt';
            }
        }
    },
    data() {
        return {
            priceDropDownState: {}
        }
    },
    setup() {

    }
}
</script>

<style scoped>
* {
    font-family: Century Gothic, sans-serif;
    font-weight: 100;
}

/* PRICE DROPDOWN */

h3 {
    padding: 10px;
    font-weight: bold;
}

.c-prist-osetr {
    margin-top: 5vh;
    display: flex;
    text-align: center;
}

.c-price-dropdown-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.c-price-dropdown-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    max-width: 150px;
    height: 70px;
    cursor: pointer;
    /* border-radius: 50%; */
    /* box-shadow: 0px 0px 4px 2px rgb(100, 100, 100); */
    border: solid;
    border-width: 1px;
}

.c-price-dropdown-control-background1 {}

.c-price-dropdown-control-background2 {}

.c-price-dropdown-control:hover {
    color: rgba(0, 0, 0, 0.4);
}

.c-price-dropdown-control-chevron {
    width: 40%;
    height: 40%;
    -webkit-mask-size: contain;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: rgba(0, 0, 0, 1.0);
}

.c-price-dropdown-control-chevron-open {
    -webkit-mask-image: url('../../public/icons/chevron-down.svg');
    mask-image: url('../../public/icons/chevron-down.svg');
}

.c-price-dropdown-control-chevron-close {
    -webkit-mask-image: url('../../public/icons/chevron-up.svg');
    mask-image: url('../../public/icons/chevron-up.svg');
}

.c-price-dropdown-control:hover .c-price-dropdown-control-chevron {
    background-color: rgba(0, 0, 0, 0.4);
}

.c-price-dropdown-control-text {}

.c-price-dropdown-invisible {
    display: none;
    height: 0px;
    transition: height 0.3s ease-out;
}

.c-price-dropdown-visible {
    display: flex;
    height: 500px;
    transition: height 0.3s ease-out;
}

.c-price-dropdown {
    height: 0px;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    width: 100%;
    height: auto;
    transition: height 0.3s ease-out;
    margin-top: 30px;
}

.c-price-dropdown-title-text-left {
    font-weight: bold;
    text-align: left;
    border-bottom: solid;
    border-width: 1px;
    padding-left: 20px;
}

.c-price-dropdown-title-text-right {
    font-weight: bold;
    text-align: right;
    border-bottom: solid;
    border-width: 1px;
    padding-right: 20px;
}

.c-price-dropdown-item {}

.c-price-dropdown-item-text {
    padding-right: 20px;
    text-align: left;
    padding-left: 20px;
    min-width: 250px;
}

.c-price-dropdown-item-price {
    margin-left: 5px;
    text-align: right;
    min-width: 120px;
    padding-right: 20px;
}

/* ************************************************************* */

/* SERVICE 1 */

#i-service1 {}

#i-service1 .c-profile-photo {
    background-image: url('@/../public/images/depilace.jpg');
}

/* ************************************************************* */

/* SERVICE 2 */

#i-service2 {}

#i-service2 .c-profile-photo {
    background-image: url('@/../public/images/Enzymatickypeeling.jpg');
}

/* ************************************************************* */

/* SERVICE 3 */

#i-service3 {}

#i-service3 .c-profile-photo {
    background-image: url('@/../public/images/laminaceoboci.png');
    background-position-y: 0px;
}

/* ************************************************************* */

/* SERVICE 4 */

#i-service4 {}

#i-service4 .c-profile-photo {
    background-image: url('@/../public/images/Enzymatickypeeling.jpg');
}

/* ************************************************************* */

/* SERVICE 5 */

#i-service5 {}

#i-service5 .c-profile-photo {
    background-image: url('@/../public/images/ocnimasaz.jpg');
}

/* ************************************************************* */

/* SERVICE 6 */

#i-service6 {}

#i-service6 .c-profile-photo {
    background-image: url('@/../public/images/salon-001mkosmeo.jpg');
}

/* ************************************************************* */

/* SERVICE 7 */

#i-service7 {}

#i-service7 .c-profile-photo {
    background-image: url('@/../public/images/trvala-1.jpg');
    background-size: cover;
    background-position-x: -100px;
    background-position-y: 0px;
}

/* ************************************************************* */

/* SERVICE 8 */

#i-service8 {}

#i-service8 .c-profile-photo {
    background-image: url('@/../public/images/ultrazvukovaspachtle.jpg');
}

/* ************************************************************* */


#i-contacts-title-container {
    width: 100%;
    /* none|h-offset v-offset blur spread color |inset|initial|inherit */
    /* box-shadow: 0px 0px 15px 10px rgb(243, 239, 230); ; */
}

#i-contacts-container {
    margin-top: 10vh;
    width: 100%;
}

#i-contacts-container>div {}

#i-contacts-top-container {}

h1 {
    width: auto;
    font-family: Century Gothic, sans-serif;
    font-weight: bold;
    text-align: center;
}

h2 {
    width: auto;
    font-family: Century Gothic, sans-serif;
    font-weight: 100;
    border-bottom: solid;
    border-width: 1px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.c-profile-photo {
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    border-radius: 50%;

    -webkit-filter: grayscale(40%);
    filter: grayscale(40%);
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;

    border: solid;
    border-color: black;
    border-width: 1px;
    box-shadow: 0px 0px 4px 2px rgb(100, 100, 100);
}

.c-profile-photo:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);

    -webkit-transition: .3s ease-in;
    transition: .3s ease-in;
}

.c-map-container {
    width: 100%;
    height: 100%;
}

.c-background1 {
    background-color: rgb(226, 211, 204);
    padding: 4%;
}

.c-background2 {
    background-color: rgb(218, 185, 169);
    padding: 4%;
}

#i-map-canvas {
    width: 100%;
    height: 100%;
    min-height: 400px;
    background: none !important;
    overflow: hidden;
    margin-top: 20px;

    border: solid;
    border-color: black;
    border-width: 1px;
}

.c-who-will-care-about-you {
    display: flex;
}

@media only screen and (min-width: 720px) {
    .c-prist-osetr {
        flex-direction: row;
    }

    .c-prist_osetr-margin-left {
        margin-left: 10px;
    }

    .c-column-text {
        width: 33%;
    }

    table {
        width: 50%;
        max-width: 500px;
    }

    .c-def-mobile-column {
        flex-direction: row;
    }

    .c-def-mobile-column-reverse {
        flex-direction: row;
    }

    .c-who-will-care-about-you {
        width: 80%;
    }

    .c-margin-top-on-mobile {}

    .c-price-dropdown-control {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 719px) {
    .c-prist-osetr {
        flex-direction: column;
        max-width: 500px;
    }

    .c-prist_osetr-margin-left {
        margin-top: 10px;
    }

    .c-column-text {
        width: 100%;
    }

    table {
        width: 80%;
    }

    .c-def-mobile-column {
        flex-direction: column;
    }

    .c-def-mobile-column-reverse {
        flex-direction: column-reverse;
    }

    .c-who-will-care-about-you {
        width: 100%;
    }

    .c-margin-top-on-mobile {
        margin-top: 20px;
    }

    .c-price-dropdown-control {
        margin-top: 20px;
    }
}
</style>